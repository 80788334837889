$arrow-button-color: #bbb;
$carousel-arrow-space: 25px;
$carousel-spacing: 25px;
$payment-data-height: 85px;
$carousel-item-width: 114px;
$carousel-base-width: $carousel-item-width * 5;

.payment-data {
  @import 'payment-data';

  // MLB
  &.MLB {
    .payment-data-group-normal {
      .payment-data-section-normal {
        max-width: 253px;
      }
    }

    .payment-data-group-trailing .payment-data-section-trailing {
      border: 0;
    }
  }

  &-section {
    &-leading,
    &-trailing {
      border: solid 0 #eee;
      flex: 0 1 auto;
      min-width: 236px;
    }

    &-trailing {
      border-left-width: 1px;
    }

    &-leading {
      box-sizing: border-box;
      border-right-width: 1px;
      color: #333;
      position: relative;
      padding-left: 32px;

      &::before {
        background-color: $andes-blue-400;
        bottom: 4px;
        content: '';
        left: 4px;
        position: absolute;
        top: 4px;
        width: 4px;
      }

      @media (width <= 970px) {
        display: none;
      }
    }
  }

  // TODO: refactor when we decide what home/paymentData experiment is left
  &.payment-data-mla {
    .carousel-container {
      padding: 0 20px 0 15px;

      &.arrow-visible {
        .slick-slider .next-button {
          right: -16px;
        }

        .slick-slider .prev-button {
          left: -15px;
        }

        .slick-slider .prev-button::before,
        .slick-slider .next-button::before {
          color: rgb(51 51 51 / 10%);
        }

        .slick-slider .next-button,
        .slick-slider .prev-button {
          display: inline;
          top: 18px;
          box-shadow: none;
          background: transparent;
        }
      }

      .slick-slider {
        margin: 0 0 0 10px;
      }
    }

    .slick-slide {
      height: 90px;
      width: 138.333px;

      .carousel-item {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        width: 138.333px;
        height: 40px;

        .carousel-item-card {
          display: inline-block;
        }

        .carousel-item-description {
          text-align: center;
          margin: 0;
          font-size: 14px;
          color: #424242;
          position: absolute;
          bottom: -10px;
          right: 0;
          left: 0;
        }
      }
    }

    .payment-data-group {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 40px;
        background-color: rgb(51 51 51 / 10%);
        right: 10px;
        top: 27px;
      }
    }

    .payment-data-title {
      display: block;
    }

    .payment-data-section > .payment-data-subtitle {
      margin: 0 18px 0 0;
    }

    .payment-data-subtitle {
      display: inline;
    }
  }
}
