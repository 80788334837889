@import '../home';

@import '~@mshops-web-components/newsletter-modal/styles/default/desktop';

@import '~@mshops-components-library/item/src/styles/desktop';
@import '~@mshops-components-library/common/src/Components/Section/styles/desktop';
@import '~@mshops-components-library/section-text/src/styles/general/desktop';
@import '~@mshops-components-library/content-list/src/styles/common/desktop';
@import '~@mshops-components-library/slide/src/styles/desktop';
@import '~@mshops-components-library/video-and-text/src/styles/general/desktop';
@import '~@mshops-components-library/products-carousel/src/styles/v2/common-v2/desktop';
@import '~@mshops-components-library/products-gallery/src/styles/common/desktop';
@import '~@mshops-components-library/categories-gallery/src/styles/templates/commons/desktop';
@import '~@mshops-components-library/categories-carousel/src/styles/desktop';
@import '~@mshops-components-library/slider/src/styles/original/desktop';
@import '~@mshops-components-library/tab-with-items/src/styles/common/desktop';
@import '~@mshops-components-library/card-action/src/styles/desktop';
@import '~@mshops-components-library/modal-add-to-cart/src/styles/iframe-modal.desktop';

@import '../../../../components/payment-data/desktop';
@import '../../../../components/badge-carousel/styles/desktop';
@import '../../../../components/card-with-image-price/styles/desktop';
@import '../../../../components/site-shopping-info/desktop';
@import '../../../../components/message/styles/desktop';
@import '../../../../components/CategoryLabelImage/styles/templates/commons/desktop';
@import '../../../../appearance/BuyerInfo/styles/desktop';

// Contact Page
@import '../../../../components/contact-form/styles/desktop';
@import '../../../../components/contact-info/styles/desktop';

// How Buy Landing
@import '../../../../components/HowBuyInfo/styles/desktop';

// Cancel Buy Landing
@import '../../../../appearance/CancelBuyInfo/styles/desktop';

// Bank Conditions Landing
@import '../../../../appearance/BankConditionsInfo/styles/desktop';

// LGPD Landing
@import '../../../../appearance/DataPrivacyInfo/styles/desktop';

// Legal Notices Landing
@import '../../../../components/LegalNoticesInfo/styles/desktop';

// Adhesion contracts Landing
@import '../../../../appearance/AdhesionContractInfo/styles/desktop';

// Not Found Page for CCP
@import '../../../../components/NotFoundCCP/styles/desktop';

// Protected Purchase Landing
@import '../../../../components/ProtectedPurchaseInfo/styles/desktop';

// Splinter desktop
@import '~@mshops-components-library/secondary-banner/styles/default/desktop';
@import '~@mshops-components-library/splinter-grid/styles/default/desktop';

@import '~@mshops-components-library/item/src/styles/tools/desktop';
@import '~@mshops-components-library/item/src/styles/material/v2/desktop';

.home {
  .row {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .hub_carousel {
    max-width: rem(1250px);

    .carousel-container {
      max-width: 95%;
    }

    .arrow-disabled {
      cursor: default;
    }
  }

  &.home--tools {
    padding-top: rem($large-header-height-tools);

    &.home--with-flash {
      padding-top: rem(
        $large-header-height-tools + $flash-component-height-tools
      );
    }
  }

  &.home--with-banner {
    padding-top: rem($andes-spacing-64);
  }
}

.home--with-banner .payment-data {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: rem($payment-methods-top-with-banner);
  width: 100%;
}

/* Modifiers for flash component and banner components */
.nav-header__banner-image {
  background-size: 100% auto;
  height: rem(200px);
  width: 100%;
}

.wrapper--with-banner + main > .home {
  padding-top: 0;

  &.home--with-payment {
    padding-top: rem($andes-spacing-40);
  }
}

.home--with-flash {
  padding-top: rem($large-header-height + $flash-component-height);
}

/* Minor styles fixes for the site shopping info */
.site-shopping-info .info-slide {
  padding-bottom: rem($andes-spacing-64);
  padding-top: rem($andes-spacing-64);
}

@media (width <= 768px) {
  .nav-header__banner {
    background-color: $light-gray;
    display: block;
    line-height: 0;
    padding-top: rem($small-header-height);
    width: 100%;
  }

  .nav-header__banner-image {
    background-size: 100% auto;
    height: rem(96px);
    width: 100%;
  }

  .home--with-flash {
    padding-top: rem($small-header-height + $flash-component-height);
  }

  .wrapper--with-banner + main > .home {
    padding-top: 0;
  }

  .home--with-payment {
    padding-top: 0 !important;
  }

  .payment-data {
    position: relative !important;
    top: 0 !important;
  }
}
